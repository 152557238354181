@media (min-width:600px){
    .background-image-print {
        height: 70%;
        width: 100%;
        background-image: url('image-print.jpeg');
        background-size: cover;
        background-position: 10% 40%;
        display: grid;
    }

    .description {
        padding-top: 50px;
        font-size: 20px;
        font-weight: 600;
        text-align: center;
    }
    .produits-print {
        display: flex;
        justify-content: center;
        gap: 145px;
        padding-left: 110px;
        margin-top: 60px;
        margin-bottom: 150px;
    }

    .image-produit {
        height: 450px;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        padding-bottom: 15px;
    }
    
.taille-produit {
    width: 250px;
}

.taille-produit2 {
    width: 370px;
    HEIGHT: 480PX;
}

.taille-produit3 {
    width: 400px
}
.produit-description {
    align-items: center;
    text-align: center;
    font-size: 18px;
}

.produit-titre {
    align-items: center;
    text-align: center;
    padding: 10px;
    padding-bottom: 20px;
    font-size: 22px;
    font-weight: 600;
}


.produit-titre1 {
    align-items: center;
    text-align: center;
    padding: 10px;
    padding-bottom: 20px;
    font-size: 22px;
    font-weight: 600;
    margin-right: 70px;
}



.produit-description1 {
    align-items: center;
    text-align: center;
    font-size: 18px;
    margin-right: 70px;

}


.print-complement {
    display: flex;
    padding: 0 50px 50px 50px;
}
.texte-complement {
    display: flex;
    flex-direction: column;
    font-size: 18px;
    padding-left: 70px;
    justify-content: center;
}
.logo-content {
    display: flex;
    height: 140px;
    justify-content: center;
    gap: 50px;
    padding-top: 30px;
    padding-bottom: 30px;
}


.hp {
    height: 100px;
}

.sharp {
    width : 200px;
    height: 200px;
    margin-top: -35px;    
}

.canon {
    width: 200px;
}

.trait {
    width: 70%;
    height: 0px;
    border-top: #b6d6d638;
    border-style: solid;
    border-width: 0.01rem;
    margin: auto;
    margin-top: 150px;
    margin-bottom: 150px;
}

.fond-print {
    background-color: rgba(255, 255, 255, 0.452);
    height: 100%;
    width: 100%;
    font-size: 55px;
    color: black;
    font-weight: 600;
    text-align: center;
    padding-top: 170px;
}
}
@media (max-width:599px){
    .background-image-print {
        height: 50vh;
        width: 100%;
        background-image: url('image-print.jpeg');
        background-size: cover;
        background-position: center;
        display: grid;
    }

    .description {
        font-size: 20px;
        font-weight: 600;
        text-align: center;
        padding-left: 5vw;
        padding-right: 5vw;
    }
    .produits-print {
        display: flex;
        justify-content: center;
        flex-direction: column;
    }

    .image-produit {
        display: flex;
        align-items: flex-end;
        justify-content: center;
        padding-bottom: 15px;
    }
    
.taille-produit {
    width: 50vw;
}

.taille-produit3 {
    width: 50vw;
}

.produit-description {
    align-items: center;
    text-align: center;
    font-size: 16px;
}

.produit-titre {
    align-items: center;
    text-align: center;
    padding: 10px;
    padding-bottom: 20px;
    font-size: 18px;
    font-weight: 600;
}


.produit-titre1 {
    align-items: center;
    text-align: center;
    padding: 10px;
    padding-bottom: 20px;
    font-size: 22px;
    font-weight: 600;
}



.produit-description1 {
    align-items: center;
    text-align: center;
    font-size: 18px;
}


.print-complement {
    display: flex;
    padding: 0 5vw;
    flex-direction: column;
    gap: 5vh
}

.texte-complement {
    display: flex;
    flex-direction: column;
    font-size: 16px;
    justify-content: center;
}

.logo-content {
    display: flex;
    justify-content: space-around;
    padding: 5vh;
}


.hp {
    height: 64px;
    width: 64px;
}

.sharp {
    width : 64px;
    height: 64px;
}

.canon {
    width: 64px;
}


.trait {
    width: 70%;
    height: 0px;
    border-top: #b6d6d638;
    border-style: solid;
    border-width: 0.01rem;
    margin: auto;
    margin-top: 75px;
    margin-bottom: 75px;
}

.fond-print {
    background-color: rgba(255, 255, 255, 0.452);
    height: 100%;
    width: 100%;
    font-size: 55px;
    color: black;
    font-weight: 600;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
}






.titre {
    font-size: 25px;
    font-weight: 600;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-top: 60px;
    color: #460f8d;
    text-decoration: underline;
}








.produit-content {
    display: flex;
    flex-direction: column;
}





.image-complement {
    border-radius: 70px;
}

