@media (min-width:600px){
    .conseil-presentation {
        display: flex;
        height: 400px;
        padding-top: 60px;
        padding-bottom: 60px;
        justify-content: center;
    }
    .conseil-presentation-texte {
        display: flex;
        flex-direction: column;
        width: 50%;
    }

    .conseil-presentation-titre{
        font-size: 55px;
        color: black;
        font-weight: 600;
        padding-bottom: 30px;
    }

    .conseil-presentation-description {
        font-size: 18px;
    }

    .conseil-image {
        margin-left: 50px;
        border-radius: 30px;
    }

    .valeur-image {
        width: 190px;
    }

    .valeur-titre {
        font-size: 22px;
        color: rgb(80, 60, 140);
        text-align: center;
        font-weight: 600;
    }

    
.valeurs {
    display: flex;
    justify-content: space-around;
    padding-bottom: 60px;
    padding-left: 200px;
    padding-right: 200px;
    background-color: rgb(191 239 235 / 27%);
}

.valeurs-component {
    display: flex;
    flex-direction: column;
}

.accompagnement-titre-principal-valeurs {
    font-size: 25px;
    font-weight: 500;
    text-align: center;
    padding-top: 30px;
    padding-bottom: 50px;
    background-color: rgb(191 239 235 / 27%);

}

.accompagnement {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    margin: 30px;
    margin-bottom: 60px;
    gap: 30px
}

}
@media (max-width:599px){
    .conseil-presentation {
        display: flex;
        justify-content: center;
        flex-direction: column;
        padding: 5vh;
        gap: 5vh
    }
    .conseil-presentation-texte {
        display: flex;
        flex-direction: column;
    }

    .conseil-presentation-titre{
        font-size: 30px;
        color: black;
        font-weight: 600;
        padding-bottom: 30px;
    }

    .conseil-presentation-description {
        font-size: 16px;
    }

    .conseil-image {
        border-radius: 30px;
    }

    .valeur-image {
        width: 64px;
    }

    .valeur-titre {
        font-size: 16px;
        color: rgb(80, 60, 140);
        text-align: center;
        font-weight: 600;
    }

    
.valeurs {
    display: flex;
    justify-content: space-around;
    padding-bottom: 60px;
    background-color: rgb(191 239 235 / 27%);
}

.valeurs-component {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.accompagnement-titre-principal-valeurs {
    font-size: 20px;
    font-weight: 500;
    text-align: center;
    padding-top: 30px;
    padding-bottom: 50px;
    background-color: rgb(191 239 235 / 27%);

}

.accompagnement {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 60px;
    gap: 30px
}
}







.accompagnement-titre-principal {
    font-size: 25px;
    font-weight: 500;
    text-align: center;
    margin-top: 60px;
    margin-bottom: 50px
}



.accompagnement-services {
    display: flex;
    flex-direction: column;
    border-radius: 25px;
    background-color: rgba(174, 30, 218, 0.089);
    width: 75%;
    padding: 15px;
}

.accompagnement-titre {
    font-size: 25px;
    font-weight: 600;
    margin-bottom: 10px;
}

.accompagnement-description {
    font-size: 19px;
}
