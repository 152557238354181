    .background-presentation {
        height: 90%;
        width: 100%;
        display: grid;
        background-image: url("background1.jpeg");
        background-position: 10% 40%;
        background-size: cover;
        font-size: 100px;
        color: rgb(255, 254, 254);
        font-size: 22px;
        font-weight: 600;
    }
    
.title-presentation {
    font-size: 60px;
    margin: 0;
    height: 30%;
    padding-top: 140px;
}

@media (max-width:599px){
    .background-presentation {
        height: 90%;
        width: 100%;
        display: grid;
        background-image: url("background1.jpeg");
        background-position: center;
        background-size: cover;
        font-size: 100px;
        color: rgb(255, 254, 254);
        font-size: 22px;
        font-weight: 600;
    }

    
.title-presentation {
    font-size: inherit;
    margin: 0;
    height: 30%;
    padding-top: 140px;
}
}

.background {
    height: 100%;
    width: 100%;
    display: grid;
    background-color: #221f1f6c;
    text-align: center;
}



.description-presentation {
    font-size: 30px;
    margin-top: -150px;
    font-style: italic;
    animation: slideDown 2s ease-in-out;
}

@keyframes slideDown {
    from {
        transform: translateY(10px);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}