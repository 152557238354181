.texte-contact-us {
    font-size: 18px;
}

@media (max-width:599px){
    .texte-contact-us {
        font-size: 16px;
        text-align: center;
    }
}

.case {
    border-style: solid;
    border-width: 4px;
    font-size: 16px;
    padding: 7px;
}