@media (min-width:600px){
    .footer {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 25px 40px 25px 40px;
        background-color: rgb(191 239 235 / 27%);
    }

    .footer1 {
        display: flex;
        justify-content: space-between;
    }

    .contactPro {
        display: flex;
        flex-direction: column;
        gap:15px;
        font-weight: 600;
    }

    .contactPro-title {
        font-size: 18px;
        color: rgb(80, 60, 140);
        font-weight: 700;
        text-decoration: overline;}

        
        .contactPro-info {
            display: flex;
            flex-direction: row;
            font-size: 16px;
            gap:15px;
        }

}

@media (max-width:599px){
    .footer {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 5vh 5vw;
        background-color: rgb(191 239 235 / 27%);
    }

    .footer1 {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        gap: 2vh
    }

    .contactPro {
        display: flex;
        gap:15px;
        font-weight: 600;
        justify-content: space-between;
        align-items: center;
    }

    .contactPro-title {
        font-size: 12px;
        color: rgb(80, 60, 140);
        font-weight: 700;
        text-decoration: overline;
        white-space: nowrap;
    }

        .contactPro-info {
            display: flex;
            flex-direction: row;
            font-size: 12px;
            gap: 1vw;
            width: 100%;
            align-items: center;
            justify-content: flex-end;
        }
}





.footer2 {
    justify-content: center;
    text-align: center;
    padding-top: 40px;
    font-weight: 600;
}

.logo {
    height: 120px;
}







.contactPro-text {
    display: flex;
    align-items: flex-start;
    margin : 0;
    margin-top: -3px;
}

a {
    color: black;
}

