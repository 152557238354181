.container {
    display: flex;
    padding: 60px 30px 60px 30px;
    flex-direction: column;
}

.mentions-titre {
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 10px;
}

.mentions-description {
    font-size: 18px;
    margin-bottom: 35px;
}