.metier-global {
    display: flex;
    padding: 0px 100px;
    height: 450px;
    background-color: rgb(191 239 235 / 27%);
}

.metier-texte {
    display: flex;
    flex-direction: column;
    width: 70%;
    justify-content: center;
}

.metier-title {
    font-size: 25px;
    font-weight: 500;
    padding-top: 10px;
}

.metier-description {
    font-size: 18px;
    padding-bottom: 10px;
}


@media (max-width:599px){
    .metier-description {
        font-size: 16px;
    }
    

}

.metier-image {
    width: 35%;
    margin: 50px 0 50px 50px;
    border-radius: 35px;
    margin-top: 100px;
    height: 270px;
}

