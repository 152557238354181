@media (min-width:600px){
    form {
        display: flex;
        flex-direction: column;
        width: 24rem;
        gap: 2rem;
        width: auto;
        padding: 40px 80px;
    }

    .parties-form {
        display: flex;
        flex-direction: row;
        gap: 45px;
    }

    
input {
    width: 250px;
}

button {
    height: 50px;
    width: 250px;
    padding: 1.4rem;
    cursor: pointer;
    background: rgb(80, 60, 140);
    color: white;
    font-weight: 600;
    border-radius: 15px;
    font-size: 15px;
    border-color: white;
}
    
}
@media (max-width:599px){
    form {
        display: flex;
        flex-direction: column;
        width: 24rem;
        gap: 2vh;
        width: auto;
        margin-top: 5vh;
        width: 90vw;
        align-items: center;
        padding: 0 5vw
    }
    
    .parties-form {
        display: flex;
        flex-direction: column;
        gap: 2vh;
    }

    form > * {
        width: 100%;
        max-width: -webkit-fill-available;
    }

    button {
        height: 50px;
        width: 250px;
        padding: 1.4rem;
        cursor: pointer;
        background: rgb(80, 60, 140);
        color: white;
        font-weight: 600;
        border-radius: 15px;
        font-size: 15px;
        border-color: white;
        align-items: center;
        display: flex;
        justify-content: center;
    }

}

.background-image-contact {
    height: 70%;
    width: 100%;
    background-image: url('contact-background.jpg');
    background-size: cover;
    display: grid;
}

.fond-contact {
    background-color: rgba(255, 255, 255, 0.5);
    height: 100%;
    width: 100%;
    font-size: 55px;
    color: black;
    font-weight: 600;
    text-align: center;
    padding-top: 170px;
}




input, select, textarea {
    padding: 1.4rem;
    background: transparent;
    border: 1px solid black;
    border-radius: 15px;
    font-size: 15px;
}


textarea {
    height: 6rem;
}


