@media (min-width:600px){
    .background-image-telecom {
        height: 70%;
        width: 100%;
        background-image: url('image-telecom.jpg');
        background-size: cover;
        background-position: 10% 42%;
        display: grid;
    }
    .fond-telecom {
        background-color: rgba(255, 255, 255, 0.5);
        height: 100%;
        width: 100%;
        font-size: 55px;
        color: black;
        font-weight: 600;
        text-align: center;
        padding-top: 170px;
    }

    .produits-telecom {
        display: flex;
        justify-content: center;
        gap: 115px;
        margin-top: 60px;
    }

    
.titre-telecom {
    font-size: 25px;
    font-weight: 600;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-top: 60px;
    color: #460f8d;
    text-decoration: underline;
    margin-left: 50px
}

.tel-complement {
    display: flex;
    padding: 0 50px 50px 50px;
    justify-content: space-around;
}

.texte-complement-content {
    display: flex;
    flex-direction: column;
    font-size: 18px;
    padding-right: 70px;
    justify-content: space-around;
    width: 810px;
}

.texte-complement-tel {
    font-size: 20px;
}

.image-complement-tel {
    width: 380px;
}

.logo-content-tel {
    display: flex;
    height: 120px;
    justify-content: center;
    gap: 40px;
    padding-top: 30px;
    padding-bottom: 30px;
}
}
@media (max-width:599px){
    .background-image-telecom {
        height: 50vh;
        width: 100%;
        background-image: url('image-telecom.jpg');
        background-size: cover;
        background-position: 10% 42%;
        display: grid;
    }

    .fond-telecom {
        background-color: rgba(255, 255, 255, 0.5);
        height: 100%;
        width: 100%;
        font-size: 55px;
        color: black;
        font-weight: 600;
        text-align: center;
        display: flex;
        justify-content: center;
        flex-direction: column;
    }

    .produits-telecom {
        display: flex;
        justify-content: center;
        flex-direction: column;
        padding: 0 5vw;
    }

    
.titre-telecom {
    font-size: 25px;
    font-weight: 600;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-top: 60px;
    color: #460f8d;
    text-decoration: underline;
}

.tel-complement {
    display: flex;
    padding: 0 5vw;
    justify-content: space-around;
    flex-direction: column;
    gap: 5vh;
    align-items: center;
}

.texte-complement-content {
    display: flex;
    flex-direction: column;
    font-size: 18px;
    justify-content: space-around;
    gap: 5vh;
}

.texte-complement-tel {
    font-size: 16px;
}

.image-complement-tel {
    width: 70vw;
}

.logo-content-tel {
    display: flex;
    justify-content: center;
    gap: 20px;
    padding-top: 30px;
    padding-bottom: 30px;
    flex-wrap: wrap;
}

.logo-content-tel > img {
    height: 62px
}
}






.description2 {
    padding: 30px 30px 0 30px;
    font-size: 20px;
    text-align: center;
}




.produit-titre {
    align-items: center;
    text-align: center;
    padding: 10px;
    padding-bottom: 20px;
    font-size: 22px;
    font-weight: 600;
}

.produit-description {
    align-items: center;
    text-align: center;
    font-size: 18px;
}


.produit-content {
    display: flex;
    flex-direction: column;
}

.image-produit-telecom {
    height: 285px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    padding-bottom: 15px;
}

.taille-solution {
    width: 350px;
}

.taille-solution2 {
    width: 380px;
}

.taille-solution3 {
    width: 350px
}



.titre-tel {
    font-size: 25px;
    font-weight: 600;
    color: #460f8d;
    text-decoration: underline;
}










