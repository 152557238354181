.card-container-global {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding-top: 0;
    padding-bottom:100px;
}

.card-title {
    font-size: 25px;
    font-weight: 500;
    margin-top: 30px;
    margin-bottom: 45px;
}

@media (min-width:600px){
    .card-container{
        display: flex;
        gap: 55px;
        justify-content: center;
    }
}

@media (max-width:599px){
    .card-container{
        display: flex;
        gap: 55px;
        justify-content: center;
        flex-direction: column;
    }
}



.contact {
    height: 500px;
    width: 100%;
    display: grid;
    background-position: center;
    background-size: cover;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: center;
    align-content: center;
    font-size: 100px;
}

.texte-contact {
    background-color: #ffffff8a;
    height: 500px;        
    display: flex;
    justify-content: center;
    align-content: center;
    flex-wrap: wrap;
    
}

.blanc {
    height: 100px;
}