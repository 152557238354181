
@media (min-width:600px){
    .background-image-info {
        height: 70%;
        width: 100%;
        background-image: url('image-info.jpg');
        background-size: cover;
        background-position: 10% 42%;
        display: grid;
    }

    .fond-info {
        background-color: rgba(255, 255, 255, 0.5);
        height: 100%;
        width: 100%;
        font-size: 55px;
        color: black;
        font-weight: 600;
        text-align: center;
        padding-top: 170px;
    }
    
    .produits-informatique {
        display: flex;
        justify-content: center;
        gap: 115px;
        margin-top: 60px;
    }

    .titre-informatique {
        font-size: 25px;
        font-weight: 600;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding-top: 60px;
        color: #460f8d;
        text-decoration: underline;
        margin-left: -100px
    }

    .taille-solution-info {
        width: 300px;
    }
    
    .taille-solution2-info {
        width: 380px;
    }
    
    .taille-solution3-info {
        width: 400px;
        height: 200px
    }

    
.image-complement-info {
    height: 400px;
    border-radius: 70px;
    width: 45%;
}

.logo-content-info {
    display: flex;
    height: 120px;
    justify-content: center;
    align-items: center;
    gap: 90px;
    padding-top: 30px;
    padding-bottom: 30px;
}


.apple {
    width: 170px
}

.microsoft {
    width: 370px;
    height: 70px;
}



}
@media (max-width:599px){
    .background-image-info {
        height: 50vh;
        width: 100%;
        background-image: url('image-info.jpg');
        background-size: cover;
        background-position: 10% 42%;
        display: grid;
    }

    .fond-info {
        background-color: rgba(255, 255, 255, 0.5);
        height: 100%;
        width: 100%;
        font-size: 40px;
        color: black;
        font-weight: 600;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .produits-informatique {
        display: flex;
        justify-content: center;
        flex-direction: column;
    }

    .titre-informatique {
        font-size: 25px;
        font-weight: 600;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding-top: 60px;
        color: #460f8d;
        text-decoration: underline;
    }

    .taille-solution-info {
        width: 70vw;
    }
    
    .taille-solution2-info {
        width: 380px;
    }
    
    .taille-solution3-info {
        width: 400px;
        height: 200px
    }

    
.image-complement-info {
    border-radius: 70px;
    width: 90vw;
}

.logo-content-info {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 30px;
    padding-bottom: 30px;
    flex-wrap: nowrap;
    justify-content: space-evenly;
}

.logo-content-info > img {
    width: 72px
}


}







.image-produit-info {
    height: 285px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    padding-bottom: 15px;
}




