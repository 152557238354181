.Logo {
    height: 90px;
}

.numero {
    color: #439597;
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 600;
}

